<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true,right:'历史反馈',feedback:true}"></v-header>
        </div>
        <div class="question_div">
            <div class="question">
                <p>{{header}}</p>
            </div>
            <div class="scheme">
                <p v-html="content"></p>
            </div>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title,
            header:'',
            content:''
        }
    },
    components:{
        vHeader
    },
    created(){
        let header = this.$route.query.header
        let content = this.$route.query.content;
        this.header = header
        this.content = content
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>
<style lang="less" scoped>
    .container{
        position: absolute;
        top: 0;
        background-color: #eee;
        .question_div{
            font-size: 16px;
            width: 100%;
            height: auto;
            background-color: #fff;
            padding-bottom: 20px;
            .question{
                margin: 10px;
                border-bottom: 1px solid #ccc;
                padding-top: 5px;
                p{
                }
            }
            .scheme{
                width: 94%;
                margin: 0 auto;
            }
        }
    }
</style>